body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
table,
div,
p {
  font-family: "NanumGothic", "맑은고딕", "Malgun Gothic", sans-serif;
}
