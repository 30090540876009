html,
body {
  height: 100%;
  background: linear-gradient(to right, #00a495, #13ad65);

  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

#root {
  vertical-align: middle;
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: #008275;
}

.App {
  display: flex;
  flex-direction: column;
  /* min-width: 360px; */

  align-items: center;

  margin: auto;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2vh;
  margin-bottom: 1vh;
  text-align: center;
}

.header-title {
  /* font-size: 72px; */
}

.header-context {
  font-size: 1.25em;
  font-weight: bold;
}

/* 
// deprecated
.load-spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: black;
  opacity: 0.8;
  z-index: 10;
} */

.load-spinner {
  color: #008275;
  width: 120px;
  height: 120px;
}

.error-page {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: black;
  opacity: 0.8;
  z-index: 10;
}

.error-message {
  color: #008275;
  font-size: 24px;
  text-align: center;
}

.crawled-at {
  margin-top: 1em;
  text-align: center;
}

.list-item-container {
  display: flex;
  height: 7.5vh;
  min-height: 70px;
  align-content: center;
  align-items: center;
  justify-content: space-between;
}

.list-item-index {
  width: 2vh;
  text-align: right;
  margin-right: 0.5em;
}

.list-item-text {
  flex: 10;
  max-width: 77.5%;
}

.list-item-button,
.list-item:focus,
.list-item-button:visited {
  display: flex;

  margin-left: 1em;
  flex: 1;

  max-width: 12.5%;

  align-self: auto;
  height: 100%;

  float: right;
}

.list-item-button:hover,
.list-item-button:active,
.list-item-button:target {
  background-color: #614d42;
  border: none;
  outline: none !important;
  box-shadow: none !important;
}

.soup-button,
.soup-button:focus,
.soup-button:visited {
  background-color: #00a495;
  border: none;
  box-shadow: none;

  align-items: center;
  justify-content: center;
}

.soup-button-reject,
.soup-button:hover,
.soup-button:active,
.soup-button:target {
  background-color: #614d42;
  border: none;
  outline: none !important;
  box-shadow: none !important;
}

.button-white {
  background-color: white;
  color: black;
}

.not-found-page {
  position: absolute;
  display: flex;
  top: 5vh;
  width: 100%;
  height: 80vh;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 700;
  font-size: 3em;
}

.memo-list-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0;
}

.memo-text {
  flex: 2;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 1em;
  word-wrap: break-word;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.last-writer {
  flex: 1;
  padding-top: 0.25rem;
  font-size: 12px;
}

.empty-memo-text {
  color: #999999;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background-color: black;
  opacity: 0.8;
  z-index: 5;
  color: white;
}

.input-form-container {
  width: 100%;
  max-width: 500px;
  min-width: 450px;
  height: 10.5vh;
  min-height: 100px;

  background-color: white;

  border-radius: 0.25rem;
  padding: 0.5rem;
}

.input-form-control {
  text-align: start;
}

.input-form-control:focus {
  border-color: #008275;
  outline: none !important;
  box-shadow: none !important;
}

.current-keyword-link {
  z-index: 20;
}

.current-keyword {
  text-align: center;
  margin: 2vh;
  font-size: x-large;
  font-weight: 700;
}

.input-button-container {
  display: flex;
  margin-top: 0.5em;
  justify-content: right;
  align-items: center;
}
.input-button-inner-container > button:first-child {
  margin-right: 0.5em;
}
.input-button-inner-container {
  margin-left: 0.5em;
}

.input-length-counter {
  margin-left: 0.5em;
}

.input-error-message {
  flex: 1;
  padding-left: 0.75rem;
  color: #ff0000;
}

.memo-list-scene {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.memo-list-scene-side {
  display: flex;
}

.memo-list-scene .list-container {
  margin: 0 0.5em;
}

.list-container {
  max-width: 500px;
  min-width: 500px;
}

.footer {
  margin-top: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  width: 100%;

  bottom: 0;

  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;
}

.footer-hits {
  margin-bottom: 1em;
}

.footer-gh-logo img {
  height: 2em;
  margin-bottom: 0.5em;
}

@media (max-width: 800px) {
  .header-context {
    font-size: 0.8em;
  }

  .list-container {
    min-width: 300px;
  }

  .list-item-button {
    max-width: 20%;
    flex: 2;

    font-size: 0.9em;
  }

  .list-item-container {
    height: 6vh;
  }

  .list-item-text {
    font-size: 0.9em;
  }

  .memo-list-scene {
    grid-template-columns: none;
  }

  .input-form-container {
    height: auto;
    margin-top: 1em;
    max-width: 100%;
    min-width: 300px;
  }

  .input-button-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .input-button-container > * {
    margin-top: 0.25em;
  }
}

.suspense-placeholder {
  height: 700px;
  width: 100%;
  max-width: 500px;
  background-color: white;
}

.password-form-container {
  display: flex;
  justify-content: center;
}

.password-form-container > input {
  width: 80%;
  max-width: 24rem;
  text-align: center;
}

.refresh-button-container {
  display: flex;
  justify-content: center;
}

.total-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 0.75rem;
}

.total-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.total-memo-text {
  overflow: visible;
  text-overflow: clip;

  word-wrap: break-word;
  line-clamp: unset;
  -webkit-line-clamp: unset;
}

.total-memo-list-item {
  width: 10rem;
  overflow-y: scroll;

  scrollbar-width: none;

  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
}
